export default {
    months:{'JAN':'01', 'FEB':'02', 'MAR':'03', 'APR':'04', 'MAY':'05', 'JUN':'06', 'JUL':'07', 'AUG':'08', 'SEP':'09', 'OCT':'10', 'NOV':'11', 'DEC':'12'},
    DAY_MILLIS:24 * 60 * 60 * 1000,
    /*
     * extract day from 'APR  20'
     */
    getDay(dateStr){
        var ret = dateStr.substring(5).trim();
        if(ret < 10)ret = "0"+ret.toString()
        return ret;
    },
    /*
     * get month number from 'APR  20'
     */
    getMonthNum(monthStr){
        var key = monthStr.substring(0,3);
        return this.months[key];
    },
    /*
     * 'YYYY-mm-DDTHH:MM:SS.000Z'
     */
    getIsoStr(mo, dy, hr){
        return '2025-'+mo+'-'+dy+'T'+hr+':00:00.000Z'
    },
    /*
     * return 'APR  20'
     * note 2 spaces
     */
    formatDate(dt){
        var options = {month:'short', day:'numeric', timeZone: 'UTC'};
        return dt.toLocaleDateString("en-US", options).replace(' ','  ').toUpperCase();
    },
    /*
     * add or subract a day based on dir=1 or -1
     */
    modifyDate(dtStr, hr, dir){
        var mo = this.getMonthNum(dtStr)
        var dy = this.getDay(dtStr);
        var iso = this.getIsoStr(mo, dy, hr);
        return new Date(Date.parse(iso) + (this.DAY_MILLIS * dir));
    },
    getDate(dtStr, hr){
        var mo = this.getMonthNum(dtStr)
        var dy = this.getDay(dtStr);
        var iso = this.getIsoStr(mo, dy, hr);
        return new Date(Date.parse(iso));

    },
    addDay(dtStr){
        return this.modifyDate(dtStr, '00', 1);
    },
    subtractDay(dtStr){
        return this.modifyDate(dtStr, '00', -1);
    },
    dateTime(dtStr, hr, utc){
        if(hr.toString().length == 1)hr = '0'+hr.toString();
        var newTime = this.getDate(dtStr, hr);
        var dOpts = {month:'short', day:'numeric', timeZoneName:'short'};
        var tOpts = { hour12: false };
        if(utc){
            tOpts.timeZone = 'UTC';
            dOpts.timeZone = 'UTC';
        } else {
            dOpts.weekday = 'short';
        }
        var retTm = newTime.toLocaleTimeString('en-US', tOpts).substring(0,5);
        var retDt = newTime.toLocaleDateString('en-US', dOpts).replaceAll(',','');
        return retTm + " " + retDt;  
    },
    localDateTime(dtStr, hr){
        return this.dateTime(dtStr, hr, false);
    },
    utcDateTime(dtStr, hr){
        return this.dateTime(dtStr, hr, true);
    }

}
